@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
}

.App {
  font-family: Arial, Helvetica, sans-serif;
}

.App-header {
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  border-bottom: 1px solid #3a3a3c;
  color: #ffffff;
  font-size: 40px;
  padding: 5px 0;
}

.App-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.App-content > * {
  text-align: center;
  margin-top: 25px;
  line-height: 1.4em;
}

.App-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  opacity: 0.7;
  line-height: 1.5em;
}

.notInWordList {
  padding: 10px;
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
  border-radius: 3px;
  animation: fade 0.52s;
  opacity: 100;
}